<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" sm="6">
        <BaseDateInput v-model="startDate"
                      :label="$t('accounting.lang_dailyReportStart')"
                      hide-details
                      dense outlined
                      type="date"/>
      </v-col>
      <v-col cols="12" sm="6">
        <BaseDateInput v-model="endDate"
                      :label="$t('accounting.lang_dailyReportEnd')"
                      hide-details
                      dense outlined
                      type="date"/>
      </v-col>
      <v-col cols="12" sm="8" class="pb-0">
        <v-select :loading="this.departments_loader" outlined dense clearable hide-details
                  :label="$t('customers.lang_customerDepartments')" :items="this.departments"
                  item-value="id" item-text="name" v-model="customer_department"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn block class="elevation-0 ma-0" color="primary" @click="filter()">
          <v-icon>filter_list</v-icon>
          {{$t('generic.lang_filter') }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="mb-3 card">


      <!-- Table -->

      <Datatable v-if="showTable" ref="unpaidInvoicesTable"
                 :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.UNPAIDINVOICES"
                 :datatable-headers="datatableHeaders"
                 :excel-columns="excelColumns"
                 excel-file-name="Offene_Rechnungen"
                 @displayEntry="displayEntry" show-selected-pdf
                 @downloadSelectedPdf="downloadSelected" :pdf-selected-loading="loadingSelectedPDF"
                 v-bind:data="this.params"
                 show-display-buttons
      >
        <template v-slot:item.total="{item}">
          {{item.total | currency}}
        </template>
      </Datatable>



    </div>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>


<script>
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from '@/config'
import mixin from "../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  components: {
    Datatable,
    BaseDateInput
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      departments_loader: false,
      departments: [],
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo"},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ],
      excelColumns: [
        {label: this.$t('generic.lang_id'), field: "ident"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ],

      id: null,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      voucherBalanceAdd: "",
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      voucherData: [],
      dataTable: null,
      customer_department: "",
      loadingSelectedPDF: false,
      pdfSelectLimit: 15, // the max number of selected invoices to export
      startDate: '',
      endDate: '',
    }
  },
  computed: {
    params: function () {
      return {
        department: this.customer_department,
        start_date: this.startDate === "" ? "" : new Date(this.startDate).getTime() / 1000,
        end_date: this.endDate === "" ? "" : new Date(this.endDate).getTime() / 1000
      }
    }
  },
  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    },
    customer_department() {
      const self = this;
      let timer = setTimeout(function () {
        self.$refs.unpaidInvoicesTable.getDataFromApi();
        clearTimeout(timer)
      }, 300)
    }
  },

  methods: {
    filter(){
      this.$refs.unpaidInvoicesTable.getDataFromApi();
    },
    loadDepartments() {
      this.departments_loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL).then((res) => {
        if (Array.isArray(res.data.results))
          this.departments = res.data.results.map((dp) => ({
            id: dp.id,
            name: dp.departmentName
          }))
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.departments_loader = false;
      })
    },
    displayEntry(entry) {
      this.uuid = entry.id;
      this.text = entry.name;
      this.$router.push({name: 'accounting.DisplayUnpaidInvoice', params: {id: this.uuid}})
    },

    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    downloadSelected(ids) {
      if (ids && ids.length > this.pdfSelectLimit) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_downloadLimit') + ' ' + this.pdfSelectLimit + ' ' + this.$t('generic.lang_selected') + ' ' + ids.length,
          color: "warning"
        });
        this.$refs.unpaidInvoicesTable.resetSelectedRows();
        return;
      }
      this.loadingSelectedPDF = true;
      this.axios.post("get/accounting/bulkInvoiceDataPDF/", {
            invoiceUUIDS: ids
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Invoice" + ".pdf");
        this.$refs.unpaidInvoicesTable.resetSelectedRows();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSelectedPDF = false;
      })
    },

  },
  mounted() {
    this.loadDepartments();
  }
}
</script>
