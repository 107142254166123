<template>
    <div>
        <page-title :heading="$t('accounting.lang_nav_accounting_openreceipt') " :subheading="$t('accounting.lang_nav_accounting_openreceipt')" :icon=icon></page-title>
        <div class="app-main__inner">
                        <UnpaidInvoices></UnpaidInvoices>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import UnpaidInvoices from "../../components/accounting/UnpaidInvoices";

    export default {
        components: {
            PageTitle,
            UnpaidInvoices
        },

        data: () => ({
            icon: 'pe-7s-bookmarks icon-gradient bg-tempting-azure',

        }),
    }
</script>